<template>
  <div class="wrapper">
    <div>
      <div>
        <button
          class="back btn btn-sm btn-outline-secondary shadow-none"
          @click="back"
        >
          <i class="bi bi-arrow-left"></i> назад
        </button>
      </div>
      <div class="content col-md-12" v-if="user">
        <div class="form-group row" v-if="USER_INFO.status === 'admin'">
          <button
            class="btn btn-primary btn-sm shadow-none"
            @click="upload_nakl_z"
          >
            загрузить или обновить накладные
          </button>
        </div>
        <div v-for="(item, idx) in keys" :key="idx">
          <div
            class="form-group row"
            v-if="USER_INFO.status === 'admin' || !item.admin"
          >
            <label class="col-md-2 col-form-label text-right">{{
              item.name
            }}</label>
            <div class="col-md-4" v-if="!item.select">
              <input
                type="text"
                class="form-control shadow-none is-disabled"
                v-model.trim="user[item.field]"
                @input="item.edit ? startEdit(item.field) : null"
                :id="`input-${item.field}`"
                :disabled="item.edit ? false : true"
              />
              <small class="form-text text-muted" v-if="item.komment">{{
                item.komment
              }}</small>
            </div>
            <div class="col-md-4" v-if="item.select && item.options">
              <select
                class="form-control form-control-sm shadow-none"
                v-model="user[item.field]"
                @change="item.edit ? startEdit(item.field) : null"
                :id="`input-${item.field}`"
              >
                <option
                  v-for="(elem, idx) in item.options"
                  :key="'m' + idx"
                  :value="
                    elem.pseudonym ||
                      elem.cod ||
                      elem.kom ||
                      elem.otchet ||
                      elem.aktiv ||
                      elem.id_user ||
                      elem.percent ||
                      elem.price ||
                      elem.visible_repair ||
                      elem.zap_free
                  "
                  >{{ elem.name }}</option
                >
              </select>
            </div>
            <div class="col-md-2 editButton" :id="item.field">
              <button
                class="btn btn-sm btn-outline-success shadow-none"
                @click="editSave(item.field, user.id)"
              >
                сохранить
              </button>
              <button
                class="btn btn-sm btn-outline-danger shadow-none"
                @click="cancelEdit(item.field)"
              >
                отмена
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "UserInfo.vue",
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    user: [],
    checkUserData: [],
    admin: null,
    keys: [
      {
        name: "наименование",
        field: "user",
        edit: true,
        admin: true
      },
      {
        name: "входит в группу СЦ",
        field: "gruppa_sc",
        edit: true,
        admin: true
      },
      {
        name: "отчитывается за группу",
        field: "gruppa_sc_admin",
        edit: true,
        admin: true,
        select: true,
        options: null
      },
      {
        name: "имя для отслеживания в ТК",
        field: "user_name",
        edit: true,
        admin: true
      },
      {
        name: "менеджер",
        field: "menedger",
        edit: true,
        admin: true,
        select: true,
        options: null
      },
      {
        name: "доступ",
        field: "aktiv",
        edit: true,
        komment: "0 - доступ на сайт разрешен, 1 - запрещен",
        admin: true,
        select: true,
        options: [
          { name: "разрешен", aktiv: "0" },
          { name: "запрещен", aktiv: "1" }
        ]
      },
      {
        name: "логин",
        field: "login",
        edit: true,
        admin: true
      },
      {
        name: "комиссия",
        field: "komissiya",
        edit: true,
        komment: "0 - нет, 1 - да",
        admin: true,
        select: true,
        options: [
          { name: "да", kom: "1" },
          { name: "нет", kom: "0" }
        ]
      },
      {
        name: "отчетность",
        field: "otchet",
        edit: true,
        komment: "да или нет",
        admin: true,
        select: true,
        options: [
          { name: "да", otchet: "да" },
          { name: "нет", otchet: "нет" }
        ]
      },
      {
        name: "ремонты не отображаются в разделе 'создание отчета'",
        field: "repairs_not_includes_in_report",
        edit: true,
        admin: true,
        select: true,
        options: [
          { name: "отображаются", visible_repair: "0" },
          { name: "не отображаются", visible_repair: "1" }
        ]
      },
      {
        name: "расценки",
        field: "report_price",
        edit: true,
        admin: true,
        select: true,
        options: [
          { name: "стандартные", price: "стандартные" },
          { name: "старые", price: "старые" },
          { name: "свои", price: "свои" },
          { name: "неавторизованный", price: "неавторизованный" }
        ]
      },
      {
        name: "1,5%",
        field: "percent",
        edit: true,
        komment: "1,5% договор",
        admin: true,
        select: true,
        options: [
          { name: "да", percent: "1" },
          { name: "нет", percent: "0" }
        ]
      },
      {
        name: "регион",
        field: "region",
        edit: true,
        admin: true,
        options: null,
        select: true
      },
      {
        name: "айди",
        field: "id",
        edit: false,
        admin: true
      },
      {
        name: "список email для уведомлений",
        field: "repair_complet_notice",
        edit: true,
        komment: "один или несколько email через пробел",
        admin: true
      },
      {
        name: "номер договора",
        field: "nomer_dog",
        edit: true,
        admin: true
      },
      {
        name: "отгрузка зч на гарантию бесплатно",
        field: "zap_free",
        edit: true,
        komment: "",
        admin: true,
        select: true,
        options: [
          { name: "да", zap_free: "1" },
          { name: "нет", zap_free: "0" }
        ]
      },
      {
        name: "email",
        field: "email",
        edit: true,
        admin: false,
        komment: "укажите один email"
      },
      { name: "адрес", field: "adres", edit: true, admin: false },
      { name: "телефон", field: "telephone", edit: true, admin: false },
      { name: "ИНН", field: "inn", edit: true, admin: false },
      { name: "КПП", field: "kpp", edit: true, admin: false },
      { name: "БИК", field: "bik", edit: true, admin: false },
      { name: "Кор. счет", field: "k_s", edit: true, admin: false },
      { name: "Банк", field: "naim_bank", edit: true, admin: false },
      { name: "Расчетный счет", field: "r_s", edit: true, admin: false },
      { name: "Руководитель", field: "ruk", edit: true, admin: false },
      {
        name: "Ответственный",
        field: "otvetstvenniy",
        edit: true,
        admin: false
      }
    ]
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      HTTPGET({
        url: "/admin/users/get_one_user.php",
        params: {
          id: this.$route.params.id
        }
      })
        .then(response => {
          this.user = response.data.a;
          this.checkUserData = response.data.b;
        })
        .then(() => {
          if (this.USER_INFO.status === "admin") {
            this.getMenedgers();
          }
        });
    },
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    startEdit(field) {
      const elem = document.getElementById(field);
      if (this.user[field] !== this.checkUserData[field]) {
        elem.classList.remove("editButton");
      } else {
        elem.classList.add("editButton");
      }
    },
    cancelEdit(field) {
      const elem = document.getElementById(field);
      elem.classList.toggle("editButton");
      this.user[field] = this.checkUserData[field];
    },
    editSave(field, id) {
      if (this.user[field] === this.checkUserData[field]) {
        this.cancelEdit(field);
      } else {
        HTTPGET({
          url: "/admin/users/edit_user.php",
          params: {
            field,
            data: this.user[field],
            id
          }
        })
          .then(() => this.getData())
          .then(() => this.cancelEdit(field));
      }
    },
    getMenedgers() {
      HTTPGET({
        url: "/admin/users/add_user.php",
        params: {
          get_menedgers: true,
          id: this.$route.params.id
        }
      }).then(response => {
        this.keys[4].options = response.data.menedgers;
        this.keys[12].options = response.data.regioni;
        this.keys[2].options = response.data.users_in_gruppa;
      });
    },
    upload_nakl_z() {
      HTTPGET({
        url: "/admin/users/upload_nakl_z/upload_nakl.php",
        params: {
          id: this.user.id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 10px;
}
.content {
  margin-top: 10px;
}
.editButton {
  display: none;
}
.btn {
  margin-right: 10px;
}
input,
label {
  font-size: 0.8em;
}
small {
  margin: 0;
}
.row {
  align-items: center;
}
</style>
